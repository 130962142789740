<template>
  <div>
    <div class="man-title">
      巡视点数据分析
    </div>
    <div class="search">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="设施位置">
          <el-select size="small" v-model="facilityId" clearable placeholder="">
            <el-option
              v-for="item in sheshi"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="onSubmit"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="list-box">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
        <el-table-column prop="facilityName" label="位置名称"></el-table-column>
        <el-table-column prop="patrolId" label="巡检点代号"></el-table-column>
        <el-table-column prop="patrolDeviceCount" label="设备数量"></el-table-column>
        <el-table-column prop="patrolTaskCount" label="巡视次数"></el-table-column>
        <el-table-column prop="abnormalCount" label="异常累计"></el-table-column>
        <el-table-column prop="missCount" label="漏检累计"></el-table-column>
        <el-table-column prop="normalCount" label="正常累计"></el-table-column>
        <el-table-column prop="repairCount" label="维修累计"></el-table-column>
        <el-table-column prop="scrapCount" label="报废累计"></el-table-column>
        <!-- <el-table-column prop="name" label="岗位">
          <template slot-scope="scope">
            <div
              v-for="item in scope.row.positionInfoList"
              :key="item.positionId"
            >{{item.positionName}}</div>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-dropdown placement="bottom-start" trigger="click">
              <span @click.stop="">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down"></i>
                </span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <page :size="size" :page="page" :total="total" v-on:pageChange="pageChange"></page>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
export default {
  components: {
    page
  },
  data() {
    return {
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [],
      sheshi: [],
      facilityId: ''
    };
  },
  methods: {
    details(row) {
      this.$router.push({ name: 'analysisPatrolDetail', params: {row: row}})
    },
    onSubmit() {
      this.page = 1
      this.loadList()
    },
    pageChange(val) {
      this.page = val
      this.loadList()
    },
    loadList() {
      this.$ajax.post("analysisPatrol", {
        facilityId: this.facilityId
      }, {
        pageNum: this.page,
        pageSize: this.size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.total
      });
    },
    load_sheshi() {
      this.$ajax.get("facilityQueryAll").then((res) => {
        this.sheshi = res.data;
      });
    },
  },
  mounted() {
    this.loadList();
    this.load_sheshi();
  },
};
</script>

<style lang="less" scoped>
.search {
  padding: 0 10px;
}
</style>